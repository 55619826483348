'use client';

import { IExpertiseComponent } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import { useAtomValue, useSetAtom } from 'jotai';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import AnimatedTitle, { AnimationType } from '../../../components/AnimatedTitle';
import Button, { ButtonVariants } from '../../../components/Button';
import Img from '../../../components/Img';
import activeHomeSection, { Section } from '../../../store/activeHomeSection';
import contactModalTriggerButton from '../../../store/contactForm';
import deviceScreenSize, { ScreenSize } from '../../../store/deviceScreenSize';
import homepageStyles from '../Home.module.scss';
import styles from './Expertises.module.scss';

interface ExpertisesProps {
  data: IExpertiseComponent | null,
  className?: string,
  id: string,
}

const Expertises = ({
  data, className, id,
}: ExpertisesProps) => {
  const activeSection = useAtomValue(activeHomeSection);
  const [shouldStartAnimation, setShouldStartAnimation] = useState(false);
  const deviceSize = useAtomValue(deviceScreenSize);
  const setContactsModalTrigger = useSetAtom(contactModalTriggerButton);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (activeSection === Section.SECOND) {
      const delay = deviceSize === ScreenSize.MOBILE ? 0 : 100;
      timeout = setTimeout(() => {
        setShouldStartAnimation(true);
      }, delay);
    }
    return () => clearTimeout(timeout);
  }, [activeSection, deviceSize]);

  return (
    <section
      // ref={ref}
      id={id}
      className={className}
    >
      <div className={styles.expertisesWrap}>
        <div className='container'>
          <div className={styles.expertises}>
            <div className={classnames(homepageStyles.sidebar__visual1, {
              // [homepageStyles.visible]: activeSection === Section.SECOND,
              [homepageStyles.visible]: true,
            })}
            >
              <div className={homepageStyles.scroller}>
                <Img
                  src='/media/Home/sidebar/scroller.svg'
                  alt='scroller'
                />
              </div>
              <div className={homepageStyles.lines}>
                <Img
                  src='/media/Home/sidebar/expertisesLines.webp'
                  alt='lines'
                />
              </div>
            </div>
            <div className={styles.servicesTitles}>
              <p className={styles.servicesTitles__title}>{data?.title}</p>
              <div className={styles.servicesTitles__content}>
                {data?.clients?.map(client => (
                  <h2
                    key={client.id}
                    className={classnames(styles.servicesTitles__item, {
                      [styles.visible]: shouldStartAnimation,
                    })}
                  >
                    {client.text}
                  </h2>
                ))}
              </div>
              <Button
                variant={ButtonVariants.OUTLINED}
                className={styles.ctaButton}
                icon={(
                  <svg>
                    <use
                      xlinkHref='/media/icon-nest.svg#iconNest'
                      href='/media/icon-nest.svg#iconNest'
                    />
                  </svg>
                )}
                iconPosition='right'
                iconSize={{ width: 32, height: 32 }}
                size={deviceSize <= ScreenSize.DESKTOP_SMALL ? 'normal' : 'big'}
                onClick={(e) => {
                  setContactsModalTrigger(`${(e.target as HTMLElement).innerText} | Expertises section`);
                }}
              >
                Start now
              </Button>
            </div>
            <div className={styles.servicesList}>
              {data?.services?.map(item => (
                <p
                  className={styles.servicesList__item}
                  key={item.id}
                >
                  <Link
                    passHref
                    href={`/${item.slug}`}
                  >
                    <AnimatedTitle
                      animationType={AnimationType.NONE}
                      title={item.title}
                    />
                  </Link>
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Expertises;
